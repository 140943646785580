<footer class="footer-area footer-bg2 pt-100">
    <div class="container">
        <div class="footer-midal pb-70">
            <div class="row">
                <div class="col-lg-4 col-sm-7">
                    <div class="footer-widget">
                        <div class="footer-img">
                            <a class="navbar-brand" routerLink="/"><img src="https://seolytics.org/demo7/media/logos/footer.png" style="max-height: 73px;max-width: 140px;" alt="Logo"></a>
                        </div>
                        <p style="text-align: justify;" >Get top spot in SERPs via high authority backlinks from real organic traffic websites with Seolytics!</p>
                        <div class="footer-social-icon">
                            <ul class="social-link">
                                <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-2 col-sm-5">
                    <div class="footer-widget">
                        <h3>Resources</h3>

                        <ul class="footer-list">
                            <li><a routerLink="/terms-conditions">Term Of Service</a></li>
                            <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                            
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-7">
                    <div class="footer-widget pl-5">
                        <h3>Company</h3>

                        <ul class="footer-list">
                            <li><a routerLink="/">Home</a></li>
                            <li><a routerLink="/about">About</a></li>
                            <li><a routerLink="/services-details">Services</a></li>
                           
                            <li><a routerLink="/contact">Contact</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-5">
                    <div class="footer-widget">
                        <h3>Address</h3>

                        <ul class="footer-list-two">
                            <li><i class='flaticon-telephone'></i><a href="tel:+923000451111">+92 300 0451111</a></li>
                            <li><i class='flaticon-email-1'></i><a href="mailto:iskillspk@gmail.com">iskillspk@gmail.com</a></li>
                            <li><i class='flaticon-place'></i>Multan</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="copy-right-area">
            <div class="row">
                <div class="col-lg-8">
                    <div class="copy-right-text text-start">
                        <p>Copyright @2021 Ribo. All Rights Reserved by <a href="https://hibootstrap.com/" target="_blank">HiBootstrap</a> </p>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="copy-right-list">
                        <ul>
                            <li><a routerLink="/privacy-policy"> Privacy Policy</a></li>
                            <li><a routerLink="/terms-conditions"> Terms & Conditions</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</footer>