<app-navbar-style-two></app-navbar-style-two>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>About Us</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>About Us</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="about-widget-area pt-50 pb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-widget-img">
                    <img src="assets/img/about/about-img4.png" alt="">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-widget-content">
                    <div class="section-title">
                        <!-- <span class="sp-before sp-after">About Us</span> -->
                        <h2>About Us</h2>
                    </div>
                    <!-- <h3>About Us</h3> -->
                    <p>Seolytics is a content marketing and off-page SEO service provider to help grow your website. Using advanced marketing practices and quality services with verified websites, Seolytics stands out and can help your website do the same.<br><br>
The agency was built to help SEBT students and bloggers to get the eyes their websites need to grow in this intensely competitive and highly dense marketplace.<br><br>
Having no luck with your website’s ranking? Struggling to find organic sources of traffic? Seolytics provides the services that your website not only needs but deserves. With a team of highly qualified professionals, advanced marketing strategies, and website partners like none others, Seolytics knows how to take care of your website.</p>
                    <div class="about-widget-list">
                        <!-- <ul>
                            <li>
                                <i class='bx bx-check'></i>
                                <span>01</span>
                                <p>High standards of professionalism, integrity. Establishment of close working relationships.</p>
                            </li>
                            <li>
                                <i class='bx bx-check'></i>
                                <span>02</span>
                                <p>A different way of thinking, both inside and outside the box. We do not have to be the best.</p>
                            </li>
                            <li>
                                <i class='bx bx-check'></i>
                                <span>03</span>
                                <p>We do not have to be the best, we do have to give our best Ability to learn. Multicultural competence.</p>
                            </li>
                        </ul> -->
                    </div>
                   <!--  <div class="about-video">
                        <a href="https://www.youtube.com/watch?v=07d2dXHYb94&t=6s" class="video-btn popup-btn"><i class='flaticon-forward'></i><span>Play Video </span></a>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>


<div class="history-area pt-100 pb-70">
    <div class="container">
       <!--  <div class="section-title text-center">
            <span class="sp-before sp-after">Our History</span>
            <h2>The Facts About Our Company</h2>
        </div> -->

       <!--  <div class="history-list">
            <ul>
                <li class="active">
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2010</h3>
                        <span>February 20th</span>
                    </div>
                </li>
                <li>
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2012</h3>
                        <span>January 14th</span>
                    </div>
                </li>
                <li>
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2016</h3>
                        <span>March 25th</span>
                    </div>
                </li>
                <li>
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2018</h3>
                        <span>December 10th</span>
                    </div>
                </li>
                <li>
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2019</h3>
                        <span>February 01th</span>
                    </div>
                </li>
            </ul>
        </div> -->

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="history-content">
                    <h2>Our Team</h2>
                    <p>We've all heard the line "teamwork makes a dream work," and here at Seolytics, we prove it. With a team of qualified, dedicated, creative, and hardworking individuals, we ensure that your tasks are handled by the best.</p><p> The founder of Seolytics, M. Tanveer Nandla, is a great trainer in SEO and blogging and one of the most experienced affiliate marketers in Pakistan. SEBT by iSkills (SEO, Blogging & Affiliate Marketing Training) is one of the most result-oriented training courses in the world and has already achieved great success in the field of the internet.</p>
                    <!-- <a routerLink="/about" class="default-btn">Learn more <i class='bx bx-plus'></i></a> -->
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="history-img">
                    <img src="assets/img/history-img.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="counter-area-two pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>16 Years</h3>
                    <p>GLORIOUS YEARS</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>100%</h3>
                    <p>SATISFACTION RATE</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>70+</h3>
                    <p>TEAM MEMBERS</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>25+</h3>
                    <p>SENIOR SEO STRATEGISTS</p>
                </div>
            </div>
        </div>
    </div>

    <div class="counter-shape-top">
        <img src="assets/img/counter/counter-shape.png" alt="Images">
    </div>
</div>

<div class="service-list-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Services</span>
            <h2>Our Journey’s Path</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="service-list-content">
                    <h3>Content Marketing</h3>
                    <p>Seolytics helps you in promoting your website with outstanding content marketing practices and tools</p>
                    <ul>
                        <li><i class='bx bx-check'></i> Understand the Process.</li>
                        <li><i class='bx bx-check'></i> Exponential Reach</li>
                        <li><i class='bx bx-check'></i> Higher Conversions</li>
                        <!-- <li><i class='bx bx-check'></i> Brilliant Minds</li> -->
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-list-content">
                    <h3>Guests Posts</h3>
                    <p>Get the opportunity to post your content on a high DA website and get your website ranked out there.</p>
                    <ul>
                        <li><i class='bx bx-check'></i>High Authority Domains</li>
                        <li><i class='bx bx-check'></i>Real Traffic Websites</li>
                        
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="service-list-content">
                    <h3>Link Insertion</h3>
                    <p>Niche edit link insertion services get you links and mentions to your website from mature and old existing blog posts</p>
                    <ul>
                        <li><i class='bx bx-check'></i> Credible Linking Sources</li>
                        <li><i class='bx bx-check'></i> High-Value Posts!</li>
                        <li><i class='bx bx-check'></i> Step By Step Follow Up</li>
                        <!-- <li><i class='bx bx-check'></i> Activate Listening</li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<<!-- div class="work-area-two pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Working Process</span>
            <h2 class="h2-color2">Simple & Clean Work Process</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-12">
                <div class="work-item-list">
                    <ul>
                        <li class="text-end">
                            <h3>Frame the Problem <span>1</span></h3>
                            <p>Lorem ipsum dolor sit amet, aut odiut podit afugitsed quia consequuntur </p>
                        </li>
                        <li class="text-end">
                            <h3>Collect the Raw Data <span>2</span></h3>
                            <p>Lorem ipsum dolor sit amet, aut odiut podit afugitsed quia consequuntur </p>
                        </li>
                        <li class="text-end">
                            <h3>Process the Data <span>3</span></h3>
                            <p>Lorem ipsum dolor sit amet, aut odiut podit afugitsed quia consequuntur </p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="work-img-2">
                    <img src="assets/img/work-img.png" alt="Images">
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="work-item-list-2">
                    <ul>
                        <li>
                            <h3><span>4</span>Explore the Data</h3>
                            <p>Lorem ipsum dolor sit amet, aut odiut podit afugitsed quia consequuntur </p>
                        </li>
                        <li>
                            <h3><span>5</span>Perform Analysis</h3>
                            <p>Lorem ipsum dolor sit amet, aut odiut podit afugitsed quia consequuntur </p>
                        </li>
                        <li>
                            <h3><span>6</span>Communicate Results</h3>
                            <p>Lorem ipsum dolor sit amet, aut odiut podit afugitsed quia consequuntur </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->


<!-- 
<div class="team-area-two pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Team</span>
            <h2 class="h2-color2">Meet Our Data Scientist to Grow Your Business</h2> 
        </div>

        <div class="team-slider-two owl-carousel owl-theme">
            <div class="team-card active">
                <img src="assets/img/team/team-img7.jpg" alt="Images">
                <div class="content">
                    <h3>Cristiono Kopper</h3>
                    <span>Web Developer</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>

            <div class="team-card team-rotated">
                <img src="assets/img/team/team-img8.jpg" alt="Images">
                <div class="content">
                    <h3>Jermin Jekson</h3>
                    <span>Marketing Manager</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>

            <div class="team-card">
                <img src="assets/img/team/team-img9.jpg" alt="Images">
                <div class="content">
                    <h3>Hobler Jeain</h3>
                    <span>General Manager</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>

            <div class="team-card team-rotated-2">
                <img src="assets/img/team/team-img10.jpg" alt="Images">
                <div class="content">
                    <h3>Julfiker Jeain</h3>
                    <span>CEO At Ostino</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>

            <div class="team-card active">
                <img src="assets/img/team/team-img11.jpg" alt="Images">
                <div class="content">
                    <h3>Hobler Jeain</h3>
                    <span>General Manager</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>

            <div class="team-card team-rotated-2">
                <img src="assets/img/team/team-img12.jpg" alt="Images">
                <div class="content">
                    <h3>Julfiker Jeain</h3>
                    <span>CEO At Ostino</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="team-two-shape">
        <div class="shape1"><img src="assets/img/shape/shape13.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape11.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape10.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape9.png" alt="Images"></div>
        <div class="shape5"><img src="assets/img/shape/shape12.png" alt="Images"></div>
        <div class="shape6"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape7"><img src="assets/img/shape/shape14.png" alt="Images"></div>
    </div>
</div>
 -->
<div class="testimonial-area testimonial-area-mb ptb-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="testimonial-list">
                    <div class="list-img-1">
                        <img src="assets/img/testimonial/testimonial1.png" alt="Images">
                    </div>

                    <div class="list-img-2">
                        <img src="assets/img/testimonial/testimonial2.png" alt="Images">
                    </div>

                    <div class="list-img-3">
                        <img src="assets/img/testimonial/testimonial3.png" alt="Images">
                    </div>

                    <div class="list-img-4">
                        <img src="assets/img/testimonial/testimonial4.png" alt="Images">
                    </div>

                    <div class="list-img-5">
                        <img src="assets/img/testimonial/testimonial5.png" alt="Images">
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="testimonial-title">
                    <h2>Words From Customers</h2>
                </div>

                <div class="testimonial-slider owl-carousel owl-theme">
                    <div class="testimonial-item">
                        <p> I'm a big fan of Seolytics. It's a great tool for improving website SEO. It's helped me to improve my website ranking on Google and to increase web traffic. Seolytics is easy to use and it's a great value for the price.</p>
                        <div class="content">
                            <img src="assets/img/testimonial/testimonial-img1.png" alt="Images">
                            <div class="content-title">
                                <h3>Zubair</h3>
                               
                            </div>
                        </div>
                    </div>

                    <div class="testimonial-item">
                        <p>I've used it for both my personal and professional websites, and the results have been great. Highly Recommended.</p>
                        <div class="content">
                            <img src="assets/img/testimonial/testimonial-img2.png" alt="Images">
                            <div class="content-title">
                                <h3>Ali</h3>
                               
                            </div>
                        </div>
                    </div>

                    <div class="testimonial-item">
                        <p>I'm loving Seolytics! It's a great tool for improving website SEO. The best part is that it's so easy to use - you don't need any special skills or knowledge. It's perfect for online business owners who want to take their website to the next level.</p>
                        <div class="content">
                            <img src="assets/img/testimonial/testimonial-img3.png" alt="Images">
                            <div class="content-title">
                                <h3>Salman</h3>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="brand-logo-area">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img  style="height: 28px;width: 120px" src="https://seolytics.org/front-site/img/veriheal.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img  style="height: 28px;width: 120px" src="https://seolytics.org/front-site/img/drivezy.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img  style="height: 28px;width: 120px" src="https://seolytics.org/front-site/img/Housing.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img  style="height: 28px;width: 120px" src="https://seolytics.org/front-site/img/drivezy.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img  style="height: 28px;width: 120px" src="https://seolytics.org/front-site/img/Etmoney.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img  style="height: 28px;width: 120px" src="https://seolytics.org/front-site/img/khata.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>