<app-navbar-style-two></app-navbar-style-two>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Contact Us</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Contact Us</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="contact-area pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Contact</span>
            <h2>Get in Touch</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="contact-card">
                    <i class="flaticon-planet-earth"></i>
                    <h3>Office Location</h3>
                    <p>Pakistan</p>
                    <!-- <p> York, NY 10018, United States.</p> -->
                    <a target="_blank" href="https://www.google.com/maps/place/Multan,+Punjab,+Pakistan/@30.1814786,71.3345742,11z/data=!3m1!4b1!4m5!3m4!1s0x393b315778dea7d9:0xbad72fc74e55d42f!8m2!3d30.157458!4d71.5249154" class="contact-card-btn">Direction <i class='bx bx-plus plus-btn'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="contact-card">
                    <i class="flaticon-email"></i>
                    <h3>Contact</h3>
                    <p>iskillspk@gmail.com</p>
                    <p>Mobile: (+92) 300 045 1111</p>
                    <a href="#" class="contact-card-btn">Know More <i class='bx bx-plus plus-btn'></i></a>
                </div>
            </div>     

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="contact-card">
                    <i class="flaticon-clock"></i>
                    <h3>Hours of Operation</h3>
                    <p>Monday - Saturday: 09:00 - 05:00</p>
                    <p>Sunday           : 10:00 - 06:00</p>
                    <a href="mailto:iskillspk@gmail.com" class="contact-card-btn">Support <i class='bx bx-plus plus-btn'></i></a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="contact-section pt-100 pb-70">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="contact-img">
                    <img src="assets/img/contact-img.png" alt="Images">
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="contact-wrap">
                    <div class="contact-form">
                        <div class="section-title">
                            <span class="sp-before sp-after">Contact</span>
                            <h2>Contact With Us</h2>
                        </div>

                        <form id="contactForm" method="POST" >
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <i class='bx bx-user'></i>
                                        <input type="text" name="name" id="name" class="form-control" required placeholder="Your Name*">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <i class='bx bx-user'></i>
                                        <input type="email" name="email" id="email" class="form-control" required placeholder="E-mail*">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <i class='bx bx-phone'></i>
                                        <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Your Phone">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <i class='bx bx-file'></i>
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Your Subject">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <i class='bx bx-envelope'></i>
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="8" required placeholder="Your Message"></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn border-radius">Send Message <i class='bx bx-plus'></i></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="contact-map">
    <div class="container-fluid m-0 p-0">
        <iframe src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Multan+(Seolytics)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>