<app-navbar-style-two></app-navbar-style-two>

<div class="banner-area-two">
    <div class="container-fluid">
        <div class="container-max-2">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="banner-content-two">
                        <!-- <span>Best for You</span> -->
                 
                     <h1>Grow Your Website <b> Traffic </b></h1>
                        <p>RANK HIGHER THROUGH HIGH AUTHORITY BACKLINKS FROM REAL ORGANIC TRAFFIC WEBSITES WITH SEOLYTICS!</p>
                        <div class="banner-content-btn">
                            <!-- <a href="#" class="learn-btn">Learn More <i class='bx bx-plus'></i></a>
                            <a href="https://www.youtube.com/watch?v=07d2dXHYb94" class="play-on-btn popup-btn">Play Video <i class='flaticon-forward'></i></a> -->
                        </div>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <div class="banner-img-2">
                        <img src="assets/img/home-two/home-two-img.png" alt="Images">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="banner-bottom-shape">
        <div class="shape-one wow fadeInUp" data-wow-delay=".3s"><img src="assets/img/home-two/home-two-shape1.png" alt="Images"></div>
        <div class="shape-two wow fadeInUp" data-wow-delay=".5s"><img src="assets/img/home-two/home-two-shape2.png" alt="Images"></div>
        <div class="shape-three wow fadeInUp" data-wow-delay=".7s"><img src="assets/img/home-two/home-two-shape3.png" alt="Images"></div>
        <div class="banner-dots1"><img src="assets/img/home-two/home-two-dots.png" alt="Images"></div>
        <div class="banner-dots2"><img src="assets/img/home-two/home-two-dots2.png" alt="Images"></div>
    </div>
</div>

<div class="service-area-two">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">WHAT OUR TOOLS DO</span>
            <h2>Tools to grow your traffic & revenue</h2>
            <p>The best and most complete data in the industry powers seolytics tools to accelerate your company’s growth in various digital avenues.</p>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="services-item">
                    <h3><a >Finally Stress Free!</a></h3>
                    <div class="services-item-img">
                        <a routerLink="/services-details"><img src="assets/img/service/service-icon7.png" alt="Images"></a>
                        <div class="img-circle"></div>
                    </div>
                    <p>
                We believe organic website traffic is one of the most crucial factors separating high-quality sites from junk websites. Thus, with our website’s collection, we promise all of the sites are real money sites with organic traffic.
            </p>
                  <!--   <a routerLink="/services-details" class="learn-btn">Learn more <i class='bx bx-plus'></i></a> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-item">
                    <h3><a >High Domain Authority</a></h3>
                    <div class="services-item-img">
                        <a routerLink="/services-details"><img src="assets/img/service/service-icon8.png" alt="Images"></a>
                        <div class="img-circle"></div>
                    </div>
                    <p>
               All the blog owners we work with have normal to high domain authority (Ahrefs DR or Moz DA).
We specialize in high-quality links placements on sites that sometimes exceed a domain authority of 80+.
            </p>
                   <!--  <a routerLink="/services-details" class="learn-btn">Learn more <i class='bx bx-plus'></i></a> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="services-item">
                    <h3><a routerLink="/services-details">Affordable Pricing</a></h3>
                    <div class="services-item-img">
                        <a ><img src="assets/img/service/service-icon9.png" alt="Images"></a>
                        <div class="img-circle"></div>
                    </div>
                    <p>
                Seolytics constantly haggles with website owners to get the most competitive prices for guest posts. Moreover, we have our own criteria to assign price tags based on domain power, site’s domain authority,  and many other factors.
            </p>
                   <!--  <a routerLink="/services-details" class="learn-btn">Learn more <i class='bx bx-plus'></i></a> -->
                </div>
            </div>
            
        </div>
    </div>

    <div class="service-two-shape">
        <div class="shape-in1"><img src="assets/img/shape/shape9.png" alt="Images"></div>
        <div class="shape-in2"><img src="assets/img/shape/shape10.png" alt="Images"></div>
        <div class="shape-in3"><img src="assets/img/shape/shape11.png" alt="Images"></div>
        <div class="shape-in4"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape-in5"><img src="assets/img/shape/shape12.png" alt="Images"></div>
    </div>
</div>

<div class="about-area-two pb-70">
    <div class="container-fluid">
        <div class="container-max">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="about-img-2">
                        <img src="assets/img/about/about-img2.png" alt="">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="about-content-two">
                        <div class="section-title">
                            <!-- <span class="sp-before sp-after">About Us</span> -->
                            <h2>What Our Tools Do</h2>
                        </div>
                        <h3>Next level SEO, content marketing, quality do-follow links, guest posts, outreach-based niche edits, link insertions, conversion rate optimization, organic traffic, and much more!</h3>
                        <!-- <p>Next level SEO, content marketing, quality do-follow links, guest posts, outreach-based niche edits, link insertions, conversion rate optimization, organic traffic, and much more!</p> -->
                        <div class="row">
                            <div class="col-lg-4 col-sm-6 pr-0">
                                <div class="about-list">
                                    <i class='bx bx-check'></i>
                                    <span>01</span>
                                    <p>Credible linking sources.</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 pr-0">
                                <div class="about-list">
                                    <i class='bx bx-check'></i>
                                    <span>02</span>
                                    <p>Development of websites.</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 pr-0">
                                <div class="about-list">
                                    <i class='bx bx-check'></i>
                                    <span>03</span>
                                    <p>Stellar service & 24x7 availability</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 pr-0">
                                <div class="about-list">
                                    <i class='bx bx-check'></i>
                                    <span>04</span>
                                    <p>100% real traffic, no fake websites.</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 pr-0">
                                <div class="about-list">
                                    <i class='bx bx-check'></i>
                                    <span>05</span>
                                    <p>Tested marketing strategies.</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 pr-0">
                                <div class="about-list">
                                    <i class='bx bx-check'></i>
                                    <span>06</span>
                                    <p>16+ Years of experience in SEO.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="counter-area-two pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>16 Years</h3>
                    <p>GLORIOUS YEARS</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>100%</h3>
                    <p>SATISFACTION RATE</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>70+</h3>
                    <p>TEAM MEMBERS</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>25+</h3>
                    <p>SENIOR SEO STRATEGISTS</p>
                </div>
            </div>
        </div>
    </div>

    <div class="counter-shape-top"><img src="assets/img/counter/counter-shape.png" alt="Images"></div>
</div>

<div class="service-another pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Our Services</span>
            <h2>We Offer Professional Services For Your Website</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="services-another-card">
                    <a routerLink="/services-details"><i class="flaticon-research service-icon service-icon-bg1"></i></a>
                    <h3><a routerLink="/services-details">Content Marketing</a></h3>
                    <p>Seolytics helps you in promoting your website with outstanding content marketing practices and tools out there ...</p>
                    <a routerLink="/services-details" class="learn-btn">Learn more <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-another-card">
                    <a routerLink="/services-details"><i class="flaticon-headphones service-icon service-icon-bg"></i></a>
                    <h3><a routerLink="/services-details">Understand the Process</a></h3>
                    <p>Content marketing is often considered a complicated subject, and most people never enter this realm due to its...</p>
                    <a routerLink="/services-details" class="learn-btn">Learn more <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-another-card">
                    <a routerLink="/services-details"><i class="flaticon-planet-earth service-icon service-icon-bg2"></i></a>
                    <h3><a routerLink="/services-details">High Authority Website Links</a></h3>
                    <p>Use verified and credible websites to promote your content for organic traffic sources</p>
                    <a routerLink="/services-details" class="learn-btn">Learn more <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-another-card">
                    <a routerLink="/services-details"><i class="flaticon-big-data service-icon service-icon-bg3"></i></a>
                    <h3><a routerLink="/services-details">Guests Posts</a></h3>
                    <p>Get the opportunity to post your content on a high DA website and get your website ranked out there.</p>
                    <a routerLink="/services-details" class="learn-btn">Learn more <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-another-card">
                    <a routerLink="/services-details"><i class="flaticon-money service-icon service-icon-bg4"></i></a>
                    <h3><a routerLink="/services-details">Link Insertion</a></h3>
                    <p>Niche edit link insertion services get you links and mentions to your website from mature and old existing blog posts...</p>
                    <a routerLink="/services-details" class="learn-btn">Learn more <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-another-card">
                    <a routerLink="/services-details"><i class="flaticon-share service-icon service-icon-bg5"></i></a>
                    <h3><a routerLink="/services-details">Exponential Reach</a></h3>
                    <p>Seolytics content marketing allows you to tap into markets you have never even considered before. It opens new doors...</p>
                    <a routerLink="/services-details" class="learn-btn">Learn more <i class='bx bx-plus'></i></a>
                </div>
            </div>
        </div>
    </div>

    <div class="service-another-shape">
        <div class="shape-1"><img src="assets/img/shape/shape13.png" alt="Images"></div>
        <div class="shape-2"><img src="assets/img/shape/shape11.png" alt="Images"></div>
        <div class="shape-3"><img src="assets/img/shape/shape10.png" alt="Images"></div>
        <div class="shape-4"><img src="assets/img/shape/shape9.png" alt="Images"></div>
        <div class="shape-5"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape-6"><img src="assets/img/shape/shape12.png" alt="Images"></div>
    </div>
</div>

<div class="work-area-two pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Working Process</span>
            <h2 class="h2-color2">Simple & Clean Work Process</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-12">
                <div class="work-item-list">
                    <ul>
                        <li class="text-end">
                            <h3>Publishing Your Link<span>1</span></h3>
                            <p>Seolytics helps you in promoting your website</p>
                        </li>
                        <li class="text-end">
                            <h3>Processing The Data<span>2</span></h3>
                            <p>Use verified and credible websites to promote </p>
                        </li>
                        <li class="text-end">
                            <h3>Exploring High Rank Links<span>3</span></h3>
                            <p>Seolytics allows you to tap into markets you have never even considered before </p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="work-img-2">
                    <img src="assets/img/work-img.png" alt="Images">
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="work-item-list-2">
                    <ul>
                        <li>
                            <h3><span>4</span>Perform Analysis</h3>
                            <p>Seolytics Provide Real and Verified Link Stats </p>
                        </li>
                        <li>
                            <h3><span>5</span>Guest Post Link</h3>
                            <p>Post your content on a high DA website</p>
                        </li>
                        <li>
                            <h3><span>6</span>Niche Edit Link</h3>
                            <p>Niche edit link insertion services get you links </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="mission-area pt-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="mission-content">
                    <div class="section-title">
                        <span class="sp-before sp-after">Mission</span>
                        <h2 class="h2-color2">We Serve As Possible That You Expected</h2>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna tetur adipisicing saliqua. </p>
                    <div class="row">
                        <div class="col-lg-4 col-sm-6 pr-0">
                            <div class="mission-list">
                                <i class='bx bx-check'></i>
                                <span>01</span>
                                <p>Retail Solutions</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 pr-0">
                            <div class="mission-list">
                                <i class='bx bx-check'></i>
                                <span>02</span>
                                <p>Expert Prepective</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 pr-0">
                            <div class="mission-list">
                                <i class='bx bx-check'></i>
                                <span>03</span>
                                <p>User Prespective</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 pr-0">
                            <div class="mission-list">
                                <i class='bx bx-check'></i>
                                <span>04</span>
                                <p>Global Experience</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 pr-0">
                            <div class="mission-list">
                                <i class='bx bx-check'></i>
                                <span>05</span>
                                <p>Value for Results</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 pr-0">
                            <div class="mission-list">
                                <i class='bx bx-check'></i>
                                <span>06</span>
                                <p>Business Vision</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="mission-img">
                    <img src="assets/img/mission-img.png" alt="Images">
                </div>
            </div>
        </div>
    </div>

    <div class="mission-shape">
        <div class="shape1"><img src="assets/img/shape/shape13.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape11.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape12.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape9.png" alt="Images"></div>
    </div>
</div> -->

<!-- <div class="team-area-two pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Team</span>
            <h2 class="h2-color2">Meet Our Professionals to Grow Website</h2> 
        </div>

        <div class="team-slider-two owl-carousel owl-theme">
            <div class="team-card active">
                <img src="assets/img/team/team-img7.jpg" alt="Images">
                <div class="content">
                    <h3>Cristiono Kopper</h3>
                    <span>Web Developer</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>

            <div class="team-card team-rotated">
                <img src="assets/img/team/team-img8.jpg" alt="Images">
                <div class="content">
                    <h3>Jermin Jekson</h3>
                    <span>Marketing Manager</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>

            <div class="team-card">
                <img src="assets/img/team/team-img9.jpg" alt="Images">
                <div class="content">
                    <h3>Hobler Jeain</h3>
                    <span>General Manager</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>

            <div class="team-card team-rotated-2">
                <img src="assets/img/team/team-img10.jpg" alt="Images">\
                <div class="content">
                    <h3>Julfiker Jeain</h3>
                    <span>CEO At Ostino</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>

            <div class="team-card active">
                <img src="assets/img/team/team-img11.jpg" alt="Images">
                <div class="content">
                    <h3>Hobler Jeain</h3>
                    <span>General Manager</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>

            <div class="team-card team-rotated-2">
                <img src="assets/img/team/team-img12.jpg" alt="Images">
                <div class="content">
                    <h3>Julfiker Jeain</h3>
                    <span>CEO At Ostino</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="team-two-shape">
        <div class="shape1"><img src="assets/img/shape/shape13.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape11.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape10.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape9.png" alt="Images"></div>
        <div class="shape5"><img src="assets/img/shape/shape12.png" alt="Images"></div>
        <div class="shape6"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape7"><img src="assets/img/shape/shape14.png" alt="Images"></div>
    </div>
</div> -->

<div class="testimonial-area ptb-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="testimonial-list">
                    <div class="list-img-1">
                        <img src="assets/img/testimonial/testimonial1.png" alt="Images">
                    </div>

                    <div class="list-img-2">
                        <img src="assets/img/testimonial/testimonial2.png" alt="Images">
                    </div>

                    <div class="list-img-3">
                        <img src="assets/img/testimonial/testimonial3.png" alt="Images">
                    </div>

                    <div class="list-img-4">
                        <img src="assets/img/testimonial/testimonial4.png" alt="Images">
                    </div>

                    <div class="list-img-5">
                        <img src="assets/img/testimonial/testimonial5.png" alt="Images">
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="testimonial-title">
                    <h2>Words From Customers</h2>
                </div>

                <div class="testimonial-slider owl-carousel owl-theme">
                    <div class="testimonial-item">
                        <p> I'm a big fan of Seolytics. It's a great tool for improving website SEO. It's helped me to improve my website ranking on Google and to increase web traffic. Seolytics is easy to use and it's a great value for the price.</p>
                        <div class="content">
                            <img src="assets/img/testimonial/testimonial-img1.png" alt="Images">
                            <div class="content-title">
                                <h3>Zubair</h3>
                               
                            </div>
                        </div>
                    </div>

                    <div class="testimonial-item">
                        <p>I've used it for both my personal and professional websites, and the results have been great. Highly Recommended.</p>
                        <div class="content">
                            <img src="assets/img/testimonial/testimonial-img2.png" alt="Images">
                            <div class="content-title">
                                <h3>Ali</h3>
                               
                            </div>
                        </div>
                    </div>

                    <div class="testimonial-item">
                        <p>I'm loving Seolytics! It's a great tool for improving website SEO. The best part is that it's so easy to use - you don't need any special skills or knowledge. It's perfect for online business owners who want to take their website to the next level.</p>
                        <div class="content">
                            <img src="assets/img/testimonial/testimonial-img3.png" alt="Images">
                            <div class="content-title">
                                <h3>Salman</h3>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">READ OUR BLOGS</span>
            <h2 class="h2-color2">Learn Content Marketing with<br> Seolytics</h2>
            <p class="margin-auto">Read about the growth marketing strategies from our community of dark-horses.</p>
        </div>

         <div class="text-center">
                    <!-- <h2>We Like to Start Your Project With Us</h2> -->
                    <a routerLink="/contact" class="default-btn mt-5">Read Our Blog<i class='bx bx-check'></i></a>
                </div>

       <!--  <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog1.png" alt="Images"></a>
                    <div class="content">
                        <ul>
                            <li><i class='bx bx-time-five'></i>09 April 2020</li>
                            <li><i class='bx bx-purchase-tag-alt'></i><a routerLink="/blog">Marketing</a></li>
                        </ul>
                        <h3><a routerLink="/blog-details">The Home of Technology is in Front of You</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog2.png" alt="Images"></a>
                    <div class="content">
                        <ul>
                            <li><i class='bx bx-time-five'></i>10 July 2020</li>
                            <li><i class='bx bx-purchase-tag-alt'></i><a routerLink="/blog">SEO</a></li>
                        </ul>
                        <h3><a routerLink="/blog-details">SEO Best Practices Mobile Friendliness</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog3.png" alt="Images"></a>
                    <div class="content">
                        <ul>
                            <li><i class='bx bx-time-five'></i>19 June 2020</li>
                            <li><i class='bx bx-purchase-tag-alt'></i><a routerLink="/blog">SEO & Marketing</a></li>
                        </ul>
                        <h3><a routerLink="/blog-details">15 SEO Practices Website Architecture</a></h3>
                    </div>
                </div>
            </div>
        </div> -->
    </div>

    <div class="blog-shape">
        <div class="shape1"><img src="assets/img/shape/shape1.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape5.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape4.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape6.png" alt="Images"></div>
    </div>
</div>

<div class="brand-logo-area">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img  style="height: 28px;width: 120px" src="https://seolytics.org/front-site/img/veriheal.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img  style="height: 28px;width: 120px" src="https://seolytics.org/front-site/img/drivezy.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img  style="height: 28px;width: 120px" src="https://seolytics.org/front-site/img/Housing.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img  style="height: 28px;width: 120px" src="https://seolytics.org/front-site/img/drivezy.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img  style="height: 28px;width: 120px" src="https://seolytics.org/front-site/img/Etmoney.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img  style="height: 28px;width: 120px" src="https://seolytics.org/front-site/img/khata.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="data-table-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="data-table-content">
                    <h2>We Like to Start Your Project With Us</h2>
                    <!-- <a routerLink="/contact" class="default-btn">Get started <i class='bx bx-plus'></i></a> -->
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="data-table-img">
                    <img src="assets/img/data-table-img.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>