<div class="navbar-area">
    <div class="mobile-nav">
        <a routerLink="/" class="logo"><img  style="max-height: 73px;max-width: 140px;" src="https://seolytics.org/demo7/media/logos/org.png" alt="Logo"></a>
    </div>

    <div class="main-nav nav-bar-two">
        <div class="container-fluid">
            <nav class="container-max-2 navbar navbar-expand-md navbar-light ">
                <a class="navbar-brand" routerLink="/"><img src="https://seolytics.org/demo7/media/logos/org.png" style="max-height: 73px;max-width: 140px;" alt="Logo"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav m-auto">
                       <!--  <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">Home <i class='bx bx-plus'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">SEO Agency</a></li>

                                <li class="nav-item"><a routerLink="/ai-startup" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">AI Startup</a></li>

                                <li class="nav-item"><a routerLink="/machine-learning" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Machine Learning</a></li>
                            </ul>
                        </li>
 -->

                         <li class="nav-item px-3"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home</a></li>
                        <li class="nav-item  px-3"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About</a></li>

                        <!-- <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">Pages <i class='bx bx-plus'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/team" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Team</a></li>

                                <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Pricing</a></li>

                                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                                <li class="nav-item"><a routerLink="/testimonials" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Testimonials</a></li>

                                <li class="nav-item">
                                    <a href="javascript:void(0);" class="nav-link">Case study <i class='bx bx-plus'></i></a>

                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/case-study" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Case study</a></li>

                                        <li class="nav-item"><a routerLink="/case-study-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Case study Details</a></li>
                                    </ul>
                                </li>

                                <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">404 Error</a></li>

                                <li class="nav-item"><a routerLink="/sign-in" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sign In</a></li>

                                <li class="nav-item"><a routerLink="/sign-up" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sign Up</a></li>

                                <li class="nav-item"><a routerLink="/terms-conditions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Terms & Conditions</a></li>

                                <li class="nav-item"><a routerLink="/privacy-policy" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Privacy Policy</a></li>

                                <li class="nav-item"><a routerLink="/coming-soon" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Coming Soon</a></li>
                            </ul>
                        </li> -->

                        <li class="nav-item  px-3">
                            <a  routerLink="/services-details" class="nav-link">Services </a>

                        
                        </li>

                       <!--  <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">Blog <i class='bx bx-plus'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog</a></li>

                                <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                            </ul>
                        </li>
 -->
                        <li class="nav-item  px-3"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                    </ul>

                    <div class="side-nav d-in-line align-items-center">
                        <div class="side-item">
                            <div class="search-box">
                                <i class="flaticon-loupe"></i>
                            </div>
                        </div>
                        <div class="side-item">
                            <div class="user-btn">
                                <a href="/login" >
                                    <div class="login_btn"  _ngcontent-buy-c19="" style="cursor: pointer; font-size: 18px; color: #ff3900; position: relative; display: inline-block; top: -2px; line-height: 35px; border-radius: 50px; width: 35px; height: 35px; background-color: #ffffff; text-align: center; transition: 0.7s;"><svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet" style="height: 22px;width: 17px;">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none" style="fill: red;">
<path d="M1988 5105 c-200 -49 -362 -209 -413 -410 -13 -50 -15 -169 -15 -783
l0 -724 34 -34 c46 -46 101 -48 148 -5 l33 29 5 734 5 733 26 55 c45 95 123
159 225 185 54 14 208 15 1330 13 1458 -3 1321 6 1430 -102 110 -110 99 136
99 -2236 0 -2372 11 -2126 -99 -2236 -109 -108 28 -99 -1430 -102 -1122 -2
-1276 -1 -1330 13 -102 26 -180 90 -225 185 l-26 55 -5 733 -5 734 -33 29
c-47 43 -102 41 -148 -5 l-34 -34 0 -724 c0 -614 2 -733 15 -783 52 -203 214
-362 418 -410 92 -22 2587 -22 2690 0 203 43 379 219 422 422 22 102 22 4144
0 4246 -43 203 -219 379 -422 422 -99 21 -2609 20 -2695 0z"></path>
<path d="M2965 3331 c-56 -24 -84 -91 -61 -143 8 -18 122 -141 255 -273 l241
-240 -1670 -5 -1670 -5 -26 -26 c-52 -52 -34 -154 32 -179 16 -6 630 -10 1682
-10 l1657 0 -247 -247 c-136 -137 -252 -261 -258 -276 -30 -83 61 -169 145
-137 17 6 178 159 378 358 354 354 374 377 360 434 -11 41 -38 70 -387 411
-362 354 -373 363 -431 338z"></path>
</g>
</svg>
</div>
                                </a>
                            </div>
                        </div>
                         <div class="side-item">
                            <div class="user-btn">
                                <a href="/register" ><i class="flaticon-contact"></i></a>
                            </div>
                        </div>
                        <div class="side-item">
                            <div class="nav-add-btn">
                                <a routerLink="/contact" class="nav-menu-btn">Contact Us
                                    <i class="bx bx-plus"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>

    <div class="side-nav-responsive">
        <div class="container">
            <div class="dot-menu">
                <div class="circle-inner">
                    <div class="circle circle-one"></div>
                    <div class="circle circle-two"></div>
                    <div class="circle circle-three"></div>
                </div>
            </div>
            
            <div class="container">
                <div class="side-nav-inner">
                    <div class="side-nav justify-content-center  align-items-center">
                       
                        <div class="side-item">
                            <div class="nav-add-btn">
                                <a href="/login" class="nav-menu-btn">Login<i class='bx bx-plus'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="search-overlay">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-layer"></div>
            <div class="search-layer"></div>
            <div class="search-layer"></div>
            
            <div class="search-close">
                <span class="search-close-line"></span>
                <span class="search-close-line"></span>
            </div>

            <div class="search-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class="flaticon-loupe"></i></button>
                </form>
            </div>
        </div>
    </div>
</div>

<style type="text/css">
    .login_btn:hover {
        background-color: black !important;
    }
</style>